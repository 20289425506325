import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <>
        <SEO title="404: Not Found" />
        <h1 className="mt-16 mb-8 text-5xl">Not Found</h1>
        <p className="mb-8">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
